import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './mock'
import wx from 'weixin-js-sdk'
import VueLazyload from 'vue-lazyload'
Vue.prototype.$wx = wx
Vue.config.productionTip = false
// 定义懒加载图片或者文件等，自定义指令
// 指令形式  缺点首次加载窗口的视图不加载，需要在滑动时才可以加载
// Vue.directive('lazy', (el, binding) => {
//   const oldSrc = el.src // 保存旧的src，方便后期渲染时候赋值src真实路径
//   el.src = '' // 将渲染的src赋为空，则不会渲染图片出来
//   const observer = new IntersectionObserver((isIntersecting) => { // 调用方法得到该elDOM元素是否处于可视区域
//     console.log(oldSrc, isIntersecting[0])
//     if (isIntersecting[0].isIntersecting) { // 回调是否处于可视区域，true or false
//       el.src = oldSrc // 如果处于可视区域额，将最开始保存的真实路径赋予DOM元素渲染
//       observer.unobserve(el) // 只需要监听一次即可，第二次滑动到可视区域时候不在监听
//     }
//   })
//   observer.observe(el) // 调用方法
// })
Vue.use(VueLazyload)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
